import React from "react"
import { graphql } from "gatsby"
import "twin.macro"

import Layout from "@components/layout"
import Seo from "@components/seo"
import SavingsAndSupportComponent from "@components/savingsAndSupport"

const SavingsAndSupportAgnosticPage = ({ location, data }) => {
  const patientVoiceData = {
    quote: (
      <>
        Starting a new treatment can seem overwhelming.{" "}
        <br tw="hidden lg:block" />
        But when you enroll in JazzCares, a nurse—like me—will{" "}
        <br tw="hidden lg:block" />
        be assigned to you and work with you from the very beginning.&quot;
      </>
    ),
    patient: "Cyndy",
    diagnosis: "Nurse Case Manager. Compensated for her time.",
    // disclaimer: "Compensated for his time. Results may vary.",
    imageData: {
      patientVoice: data.patientVoice,
      patientVoiceMobile: data.patientVoiceMobile,
    },
    imageRight: true,
    shortHeight: true,
  }

  const videoMetaData = [
    {
      metadata: {
        name: "Watch a JazzCares® Nurse Case Manager walk you through all JazzCares has to offer | XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Learn about enrolling in JazzCares® once you are prescribed XYWAV. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.thumbImage.publicURL,
        uploadDate: "2023-11-13",
        contentUrl: `https://www.xywav.com/getting-xywav/?wvideo=wc6ibtt719`,
        duration: "PT6M7S",
      },
    },
  ]
  return (
    <Layout
      location={location}
      headerType="narcolepsy"
      footerType="narcolepsy"
      exitModalColour="red"
      copyright={
        <>
          &copy; 2024 Jazz Pharmaceuticals, Inc.{" "}
          <nobr>US-XYW-2200416 Rev1124</nobr>
        </>
      }
    >
      <Seo
        title="JazzCares® & Cost Savings | XYWAV®"
        description="Get support and resources with JazzCares® for XYWAV® (calcium, magnesium, potassium, and sodium oxybates) oral solution. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
        videoMetadata={videoMetaData}
      />
      <SavingsAndSupportComponent
        theme="agnostic"
        patientVoice={patientVoiceData}
        videoThumbnails={data}
      />
    </Layout>
  )
}

export default SavingsAndSupportAgnosticPage

export const query = graphql`
  query {
    thumbImage: file(
      relativePath: { eq: "video-thumbnails/jazzcares-video-thumbnail.png" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    patientVoice: file(
      relativePath: { eq: "savings-and-support/cyndy-desktop.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 425, placeholder: NONE)
      }
    }
    patientVoiceMobile: file(
      relativePath: { eq: "savings-and-support/cyndy-mobile.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 767, placeholder: NONE)
      }
    }
    myWavVidThumb: file(
      relativePath: { eq: "video-thumbnails/mywav-video-thumbnail.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
  }
`
